import { ThunkAction } from "StoreTypes";
import { Dispatch } from "redux";
import { Throwable } from "@wss/error-tracking/throwable";
import { toError } from "@wss/error-tracking/utils";
import { toast } from "@clarkinc/zest-design-system/ui";
import { updateChat } from "./chat";
import { updateUser } from "./user";
import { Chat, User } from "../connectUserAndChat";
import getTrackerData from "~/common/helpers/getTrackerData";

let resolveGetDynamicData: (value: Throwable) => void;

export const getDynamicData = new Promise<Throwable>((resolve, reject) => {
  resolveGetDynamicData = resolve;
});

export const updateUserAndChatFromOrigin = (): ThunkAction => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: "UPDATE_USER_AND_CHAT_FROM_ORIGIN",
  });

  const {
    Referer: referer,
    EmailTrackingData: emailTrackingData,
  } = getTrackerData();

  try {
    const response = await fetch(
      `/api:edgecache/getdynamicdata/?referer=${referer}` +
        `&ltid=${emailTrackingData.LtId}` +
        `&dlid=${emailTrackingData.DlId}` +
        `&link=${emailTrackingData.Link}`,
      {
        credentials: "include",
      }
    );

    const { user, chat } = await response.json<{ user: User; chat: Chat }>();

    if (!user.skipSettingGDDFetch) {
      user.lastGetDynamicDataFetch = new Date().getTime();
    }

    const camelcaseKeysImport = import("camelcase-keys");
    const { default: camelcaseKeys } = await camelcaseKeysImport;

    user.wishLists = camelcaseKeys(user.wishLists);

    dispatch(updateUser(user));
    dispatch(updateChat(chat));

    resolveGetDynamicData({
      isError: false,
    });
  } catch (e) {
    const error = new Error(
      "Error fetching and formatting User and Chat data from /api:edgecache/getdynamicdata/",
      {
        cause: toError(e),
      }
    );

    toast.error("Please refresh your browser and try again", {
      title: "We were unable to load your account",
    });

    resolveGetDynamicData({
      error,
      isError: true,
    });
  }
};

export const isUserDataFresh = (user?: User): boolean => {
  if (user && user.skipSettingGDDFetch) {
    return true;
  }
  return new Date().getTime() - (user?.lastGetDynamicDataFetch || 0) < 2000;
};
