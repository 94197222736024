import React, { useState } from "react";
import { cls } from "cls";
import { connect } from "react-redux";
import { NavItemData } from "../DesktopNav/Item";
import { RootState, UserState } from "~/common/Store/models/RootReadonly";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import AccountStepTwo from "./AccountStepTwo";

interface Props {
  navDataItems: NavItemData[];
  mobileNavOpen: boolean;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  user: UserState;
  mounted: boolean;
}

const MobileNav = ({
  navDataItems,
  mobileNavOpen,
  setCurrentStep,
  currentStep,
  user,
  mounted,
}: Props) => {
  const [clickedNavItem, setClickedNavItem] = useState<NavItemData>();

  return (
    <div className="block lt:hidden container">
      <nav
        id="flyout-nav-redesign"
        aria-label="primary navigation"
        className={cls(
          "bg-white",
          "block",
          "clearfix",
          "absolute",
          "right-0",
          "bottom-0",
          "left-0",
          "z-[55]",
          "transition",
          "ease-in-out",
          "duration-150",
          "xs:w-1/2",
          "overflow-y-auto",
          "mobile-menu-min-width",
          "new-header-nav",
          "top-[108px]",
          "md:top-[126px]",
          mobileNavOpen ? "translate-x-0" : "-translate-x-full"
        )}
        {...{ inert: !mobileNavOpen ? "" : undefined }}
        role="navigation"
      >
        <ul className="clearfix m-0 pl-0" data-testid="navbar">
          <li
            id="product-categories"
            className="rounded-none border-0 block w-full"
          >
            <div className="m-0 w-full">
              {mounted && currentStep === 1 && (
                <StepOne
                  user={user}
                  navDataItems={navDataItems}
                  setCurrentStep={setCurrentStep}
                  setClickedNavItem={setClickedNavItem}
                />
              )}
              {mounted && currentStep === 2 && clickedNavItem === undefined && (
                <AccountStepTwo setCurrentStep={setCurrentStep} user={user} />
              )}
              {mounted && currentStep === 2 && clickedNavItem && (
                <StepTwo
                  setClickedNavItem={setClickedNavItem}
                  clickedNavItem={clickedNavItem}
                  setCurrentStep={setCurrentStep}
                />
              )}
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export const mapStateToProps = (state: RootState, ownProps: Props) => {
  if (!ownProps.mounted) return ownProps;

  return state;
};

export default connect(mapStateToProps)(MobileNav);
