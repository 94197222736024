import React from "react";
import { Image } from "@wss/image";
import { NavItemData } from "./Item";
import FastAnchor from "~/common/FastAnchor";

interface Props {
  data: NavItemData;
  isOpenFlyout: boolean;
}

const FlyoutNavigation = ({ data, isOpenFlyout }: Props) => {
  return (
    <div
      className={`fly-item bg-white box-border overflow-hidden mt-0 w-full top-10 md:mx-auto md:header-max-w-md lt:shadow-flyout lt:rounded-b p-4 xl:p-6 absolute lt:right-0 lt:left-0 lt:header-max-w-lt lg:header-max-w-lg lz:header-max-w-lz xl:header-max-w-lz xxl:header-max-w-xxl ${
        isOpenFlyout ? "block h-auto" : "hidden"
      }`}
      data-testid="flyout"
      id="flyout"
    >
      <div className="!flex items-start justify-between w-full gap-4 xl:gap-6">
        <div className="w-1/2">
          <p
            className="border-0 border-b border-gray-100 border-solid text-xl font-semibold leading-6 mt-0 mb-0 pb-4 text-gray-800 w-full"
            data-testid="flyout-title"
          >
            {data.moreInText}
            <a
              aria-label={`Shop All ${data.moreInText}`}
              href={data.link}
              className="text-sm ml-4 text-green-500 hover:underline active:underline active:text-green-800 mb-4"
            >
              Shop All
            </a>
          </p>
          <div className="box-border mb-5 w-full">
            <ul className="list-none m-0 p-0 grid gap-4 mt-6 grid-cols-2 xxxl:grid-cols-3 xxxl:gap-6">
              {data.featuredResources.map((featuredResource) => (
                <li
                  key={featuredResource.name}
                  className="border-0 border-b border-gray-100 border-solid last:border-b-0 box-border block text-sm leading-4 my-0 lt:border-0 lt:flex-1 font-semibold p-0 text-center"
                >
                  <FastAnchor href={featuredResource.link}>
                    <div className="!flex items-center gap-3">
                      <Image
                        src={featuredResource.image}
                        className="border border-solid border-gray-300 p-1 rounded-md shadow-sm"
                        width="64"
                        height="64"
                        aria-hidden="true"
                        alt={featuredResource.name}
                      />
                      <p className="text-left text-base font-semibold leading-5 hover:underline text-green-500 active:text-green-700 active:underline mb-0 p-0">
                        {featuredResource.name}
                      </p>
                    </div>
                  </FastAnchor>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-1/2">
          <div className="bg-gray-50 rounded-lg px-4 py-4-1/2 xxxl:p-6">
            <p className="font-bold text-base leading-none mb-0 py-1.5 px-2 text-gray-800 w-full">
              More in {data.moreInText}
            </p>
            <ul className="list-none m-0 px-0 pt-2 w-full !flex">
              <div className="columns-2 xxxl:columns-3 w-full">
                {data.subResources.map((item) => (
                  <li className="w-full" key={item.name}>
                    <FastAnchor
                      href={item.link}
                      className="!flex text-sm font-normal leading-4 py-1.5 px-2 text-gray-800 no-underline hover:underline hover:rounded hover:bg-gray-100 active:text-green-700"
                    >
                      {item.name}
                    </FastAnchor>
                  </li>
                ))}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlyoutNavigation;
