import React, { Suspense, useEffect, useState } from "react";
import useIsMounted from "~/common/useIsMounted";
import CategoryItem from "./CategoryItem";
import FlyoutNavigation from "./FlyoutNavigation";

export interface FeaturedResource {
  name: string;
  image: string;
  link: string;
  childrenAreProducts: boolean;
}

export interface NavItemData {
  displayName: string;
  cssClassModifier: string;
  link: string;
  moreInText: string;
  featuredResources: FeaturedResource[];
  subResources: FeaturedResource[];
}

interface ItemProps {
  data: NavItemData;
  flyoutIsOpen: boolean;
  expandedFlyout: string;
  handleKeyboardToggle(e: React.MouseEvent<HTMLButtonElement>): void;
  mouseEnterMenuItem(displayName: string): void;
}

export default function Item({
  data,
  flyoutIsOpen,
  expandedFlyout,
  handleKeyboardToggle,
  mouseEnterMenuItem,
}: Readonly<ItemProps>) {
  const isOpenFlyout = expandedFlyout === data.displayName;
  const isMounted = useIsMounted();

  const [isButtonFocused, setIsButtonFocused] = useState(false);

  const handleButtonFocus = () => setIsButtonFocused(true);
  const handleButtonBlur = () => setIsButtonFocused(false);

  useEffect(() => {
    // Clean up on unmount or focus changes
    return () => {
      setIsButtonFocused(false);
    };
  }, []);

  return (
    <>
      <CategoryItem
        link={data.link}
        displayName={data.displayName}
        isOpenFlyout={isOpenFlyout}
        mouseEnterMenuItem={mouseEnterMenuItem}
        isRelatedButtonFocused={isButtonFocused}
      />
      {isMounted && (
        <Suspense fallback={null}>
          <button
            type="button"
            className="offscreen peer"
            aria-expanded={isOpenFlyout}
            data-type={data.displayName}
            data-testid="keyboardButton"
            onClick={(e) => {
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              handleKeyboardToggle(e);
            }}
            onFocus={handleButtonFocus}
            onBlur={handleButtonBlur}
          >
            <span>{data.displayName.replace(/<br ?\/?>/g, "")} Submenu</span>
          </button>
        </Suspense>
      )}
      {flyoutIsOpen && expandedFlyout && (
        <FlyoutNavigation data={data} isOpenFlyout={isOpenFlyout} />
      )}
    </>
  );
}
