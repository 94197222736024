/* eslint-disable camelcase */
import { Product } from "~/userAnalytics";
import { carouselIdDimension, itemNumberDimension } from "../definitions";

type GtmProduct = Omit<Product, "name" | "brand" | "id"> & {
  item_name: string;
  item_brand: string;
  item_id: string;
  index?: number;
};

type EcommerceProducts = Partial<GtmProduct>[];

export interface EcommerceData {
  currency: string;
  value?: number;
  items?: EcommerceProducts;
  coupon?: string;
}

interface OptionalFields {
  position?: number;
  // eslint-disable-next-line camelcase
  item_list_name?: string;
  total?: number;
  coupons?: string;
}

export const formatEcommerceData = (
  products: Product[],
  optionals?: OptionalFields
) => {
  const googleProducts: GtmProduct[] = [];

  const ecommerceData: EcommerceData = {
    currency: "USD",
  };
  for (const product of products) {
    const isValidCartQuantity =
      product.quantity && Number(product.quantity) <= 30000;
    if (isValidCartQuantity) {
      const googleProduct = {
        item_id: product?.id,
        item_name: product?.name,
        [itemNumberDimension]: product[itemNumberDimension],
        [carouselIdDimension]: product[carouselIdDimension],
        item_brand: product?.brand,
        item_category: product?.item_category,
        item_category2: product?.item_category2,
        item_category3: product?.item_category3,
        item_category4: product?.item_category4,
        item_category5: product?.item_category5,
        price: product.price,
        index: optionals?.position,
        // Some marketing product items have a quantity of "0" returned, but we want to send 1 to GA for measuring ecommerce data
        quantity: product.quantity === "0" ? "1" : product.quantity,
        item_list_name: optionals?.item_list_name,
        category: product?.category,
        categoryId: product?.categoryId,
      };
      googleProducts.push(googleProduct);
    }
  }
  ecommerceData.items = googleProducts;

  if (optionals?.total) {
    ecommerceData.value = optionals.total;
  }
  if (optionals?.coupons) {
    ecommerceData.coupon = optionals.coupons;
  }

  return ecommerceData;
};
