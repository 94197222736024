import React from "react";
import { cls } from "cls";

interface Props {
  pendingOrders?: number;
}

const PendingOrders = ({ pendingOrders }: Props) => (
  <span className="flex items-center" data-testid="pending-orders">
    Pending Orders{" "}
    <span
      className={cls(
        "whitespace-nowrap",
        "bg-green-500",
        "rounded-full",
        "inline-block",
        "font-bold",
        "text-2xs",
        "leading-normal",
        "ml-2",
        "text-white",
        "align-top",
        "py-px",
        "px-[7px]"
      )}
    >
      {pendingOrders}
    </span>
  </span>
);

export default PendingOrders;
