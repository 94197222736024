import React from "react";
import { Icon } from "@clarkinc/zest-design-system";
import spritePath from "~/common/Icon/critical-symbol.svg";

type PhoneBadgeProps = {
  classNames?: string;
};

const PhoneBadge = ({ classNames }: PhoneBadgeProps) => {
  return (
    <Icon
      className={`h-4 w-4 mr-0.5 ${classNames}`}
      name="smartphone-icon"
      size="small"
      spriteSheetHref={spritePath}
    />
  );
};

export default PhoneBadge;
