import Store from "./common/Store";
import { getDynamicData } from "./common/Store/actions/updateUserAndChatFromOrigin";
import Environment from "./common/Environment";
import getTrackerData from "~/common/helpers/getTrackerData";

const logToTracker = async () => {
  await getDynamicData;
  const { index, cfid } = Store.getState().user;
  const { ip } = Store.getState().user.analyticsData;
  let ttfb = 0;

  let userIndex = 0;
  if (window.performance) {
    const performanceTiming = window.performance.timing;
    ttfb =
      (performanceTiming.responseStart - performanceTiming.requestStart) / 1000;
  }

  if (index) {
    userIndex = index;
  }

  const {
    IsStoreFrontApp: isStoreFrontApp,
    TrackedPage: trackedPage,
  } = getTrackerData();

  if (ip && cfid) {
    const data = {
      userId: parseInt(userIndex, 10),
      company: "webstaurantstore.com",
      page: trackedPage,
      referer: document.referrer.substring(0, 199),
      loadTime: parseFloat(ttfb.toFixed(5)),
      serverNumber: 0,
      userAgent: window.navigator.userAgent.substring(0, 254),
      pageNotes: isStoreFrontApp ? "loaded from storefront" : "",
      IpAddress: ip.substring(0, 44),
      Cfid: cfid.substring(0, 69),
      DateViewed: new Date().toISOString(),
    };

    const url = new URL(
      "/PageView",
      `https://${Environment.stashDomainWithEnvironment}`
    );

    try {
      const result = await fetch(url.href, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!result.ok) {
        return {
          isError: true,
          error: new Error(
            `Stash page view fetch error: "${result.statusText}"`
          ),
        };
      }
      return { isError: false };
    } catch (error) {
      return {
        isError: true,
        error: new Error("Stash page view fetch error", { cause: error }),
      };
    }
  }
  return { isError: false };
};

const tracker = () => {
  logToTracker();
};

export default tracker;
