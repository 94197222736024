/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useRef, useEffect } from "react";
import { cls } from "cls";
import Item, { NavItemData } from "./Item";
import ShowMoreCategories from "./ShowMoreCategories";

interface Props {
  navDataItems: NavItemData[];
  checkout: boolean;
}

const DesktopNav = ({ navDataItems, checkout }: Props) => {
  const [flyoutIsOpen, setFlyoutIsOpen] = useState(false);
  const [expandedFlyout, setExpandedFlyout] = useState("");
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const [isHomePage, setIsHomePage] = useState(false);

  const mouseEnterNav = () => {
    if (timerRef.current) return;
    timerRef.current = setTimeout(() => {
      setFlyoutIsOpen(true);
    }, 300);
  };

  const mouseLeaveNav = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = undefined;
      setTimeout(() => {
        setFlyoutIsOpen(false);
      }, 300);
    }
  };

  const mouseEnterMenuItem = (displayName: string) => {
    if (displayName) {
      setExpandedFlyout(displayName);
    }
  };

  const mouseLeaveMenuItem = (displayName: string) => {
    if (displayName) {
      setExpandedFlyout("");
    }
  };

  const handleKeyboardToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const button = e.target;
    if (!(button instanceof HTMLButtonElement)) return;
    const { type } = button.dataset;

    if (flyoutIsOpen) {
      setExpandedFlyout("");
      setFlyoutIsOpen(false);
    } else {
      setFlyoutIsOpen(true);
      setExpandedFlyout(type ?? "");
    }
  };

  const handleEscapeKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setFlyoutIsOpen(false);
    }
  };

  useEffect(() => {
    if (document.location.pathname === "/") {
      setIsHomePage(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [flyoutIsOpen]);

  return (
    <>
      {!checkout && (
        <nav
          id="flyout-nav-redesign"
          data-testid="flyout-nav"
          aria-label="primary navigation"
          className="hidden
            -translate-x-full
            lt:block
            clearfix
            h-auto
            overflow-x-hidden
            overflow-y-scroll
            pl-0
            absolute
            bottom-0
            left-0
            z-[55]
            lt:translate-x-0
            lt:rounded-normal
            mt-0
            max-w-full
            lt:overflow-visible
            lt:pt-0
            lt:relative
            lt:w-full
            lt:top-0"
          role="navigation"
        >
          <div className="container bg-origin-box-border block relative">
            <div
              onMouseLeave={mouseLeaveNav}
              className={cls(
                "flex",
                "flex-nowrap",
                "items-center",
                "justify-between",
                "h-full",
                "bg-green-500",
                "rounded-t",
                !isHomePage ? "rounded-b" : ""
              )}
            >
              <ul
                data-testid="navbar"
                onMouseOver={mouseEnterNav}
                className="flex
                   m-0
                  font-semibold
                  text-sm
                  list-none
                  text-white
                  w-full
                  flex-row
                  lt:flex-nowrap
                  lt:items-center
                  lt:relative
                  lt:space-y-0
                  min-h-10
                  px-1.5"
              >
                <div
                  data-testid="nav-full-data-items"
                  className="lt:hidden xl:flex xl:w-full group"
                >
                  {navDataItems.slice(0, navDataItems.length)?.map((item) => (
                    <Item
                      data={item}
                      expandedFlyout={expandedFlyout}
                      flyoutIsOpen={flyoutIsOpen}
                      handleKeyboardToggle={handleKeyboardToggle}
                      key={item.displayName}
                      mouseEnterMenuItem={mouseEnterMenuItem}
                    />
                  ))}
                </div>
                <div
                  data-testid="nav-show-more-data-items"
                  className="lt:flex lt:w-full xl:hidden"
                >
                  {navDataItems
                    .slice(0, navDataItems.length - 3)
                    ?.map((item) => (
                      <Item
                        data={item}
                        expandedFlyout={expandedFlyout}
                        flyoutIsOpen={flyoutIsOpen}
                        handleKeyboardToggle={handleKeyboardToggle}
                        key={item.displayName}
                        mouseEnterMenuItem={mouseEnterMenuItem}
                      />
                    ))}
                </div>
                <div
                  data-testid="show-more-categories-container"
                  className="lt:block xl:hidden"
                >
                  <ShowMoreCategories
                    navDataItems={navDataItems.slice(
                      navDataItems.length - 3,
                      navDataItems.length
                    )}
                    mouseEnterMenuItem={mouseEnterMenuItem}
                    mouseLeaveMenuItem={mouseLeaveMenuItem}
                  />
                </div>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default DesktopNav;
