import React, { useEffect, useState } from "react";
import { cls } from "cls";
import { UserState } from "~/common/Store/models/RootReadonly";
import PlatinumPlusLogo from "./PlatinumPlusLogo";
import PlatinumPlusCenterLogo from "./PlatinumPlusCenterLogo";
import PlatinumLogo from "./PlatinumLogo";
import PlatinumCenterLogo from "./PlatinumCenterLogo";
import PlusLogo from "./PlusLogo";
import StandardLogo from "./StandardLogo";
import PlusCenterLogo from "./PlusCenterLogo";
import StandardCenterLogo from "./StandardCenterLogo";
import FastAnchor from "../../../FastAnchor";

interface Props {
  user: UserState;
  checkout: boolean;
}

const sharedDesktopStyles = cls("hidden", "lt:block");
const sharedMobileStyles = cls("lt:hidden");

const Logos = ({ user, checkout }: Props) => {
  const [logoKey, setLogoKey] = useState<string | null>(null);

  useEffect(() => {
    if (user.hasWebPlus && user.platinum.enabled) {
      setLogoKey("platinumPlus");
      return;
    }
    if (user.hasWebPlus) {
      setLogoKey("webPlus");
      return;
    }
    if (user.platinum.enabled) {
      setLogoKey("platinum");
      return;
    }
    setLogoKey("standard");
  }, [user, logoKey]);

  const isPlatinumPlus = logoKey === "platinumPlus";
  const isPlatinum = logoKey === "platinum";
  const isWebPlus = logoKey === "webPlus";
  const isStandardLogo = logoKey === "standard";

  if (isPlatinumPlus && checkout) {
    return (
      <>
        <PlatinumPlusLogo
          fillColor="#636E5B"
          classNames={sharedDesktopStyles}
        />
        <PlatinumPlusLogo fillColor="#fff" classNames={sharedMobileStyles} />
      </>
    );
  }
  if (isPlatinumPlus && !checkout) {
    return (
      <>
        <PlatinumPlusLogo
          fillColor="#636E5B"
          classNames={sharedDesktopStyles}
        />
        <PlatinumPlusCenterLogo classNames={sharedMobileStyles} />
      </>
    );
  }
  if (isPlatinum && checkout) {
    return (
      <>
        <PlatinumLogo fillColor="#636E5B" classNames={sharedDesktopStyles} />
        <PlatinumLogo fillColor="#FFFFFF" classNames="lt:hidden" />
      </>
    );
  }
  if (isPlatinum && !checkout) {
    return (
      <>
        <PlatinumLogo fillColor="#636E5B" classNames={sharedDesktopStyles} />
        <PlatinumCenterLogo classNames={sharedMobileStyles} />
      </>
    );
  }
  if (isWebPlus) {
    return (
      <>
        <PlusLogo fillColor="#298200" classNames={sharedDesktopStyles} />
        <PlusCenterLogo classNames={sharedMobileStyles} />
      </>
    );
  }
  if (isStandardLogo) {
    return (
      <>
        <FastAnchor
          aria-label="Homepage, WebstaurantStore"
          href="/"
          data-testid="logo"
          className={`${sharedDesktopStyles} w-full xxl:w-auto`}
        >
          <StandardLogo
            fillColor="#298200"
            fillSecondColor="#636E5B"
            logoClassNames={cls(
              "block",
              "max-w-[230px]",
              "lg:max-w-[250px]",
              "lz:max-w-[280px]",
              "xl:max-w-[336px]",
              "h-5",
              "lg:h-[22px]",
              "lz:h-[25px]",
              "xl:h-[31px]",
              "w-full"
            )}
          />
        </FastAnchor>
        <StandardCenterLogo classNames={sharedMobileStyles} />
      </>
    );
  }
  return <></>;
};

export default Logos;
