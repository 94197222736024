import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { ThunkDispatch } from "StoreTypes";
import Store from "../Store";
import { NavItemData } from "./Nav/DesktopNav/Item";
import { defaultChatObject, defaultUserObject } from "../Store/rootReducer";
import { updateUserAndChatFromOrigin } from "../Store/actions/updateUserAndChatFromOrigin";
import LoggedInAsBanner from "./LoggedInAsBanner";
import UnsupportedBrowserBanner from "./Banner/UnsupportedBrowserBanner";
import HeaderContainer from "./HeaderContainer";

type HeaderProps = {
  navDataItems: NavItemData[];
  checkout: boolean;
  productCount: number;
  imageSearchFeatureFlag?: boolean;
};

const Header = ({
  navDataItems,
  checkout,
  productCount,
  imageSearchFeatureFlag,
}: HeaderProps) => {
  const [mounted, setState] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [mobileExpandedSection, setMobileExpandedSection] = useState("");

  useEffect(() => {
    setState(true);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (Store.dispatch as ThunkDispatch)(updateUserAndChatFromOrigin());
  }, []);

  useEffect(() => {
    // Work around so we do not have remove the page-container class from _Layout.cshtml file
    if (document) {
      const pageContainerElement = document.getElementsByClassName(
        "page-container"
      )[0];

      if (pageContainerElement) {
        pageContainerElement.classList.add("new-header");
      }
    }
  }, []);

  return (
    <Provider store={Store}>
      <UnsupportedBrowserBanner mounted={mounted} user={defaultUserObject} />
      <LoggedInAsBanner mounted={mounted} />
      <HeaderContainer
        user={defaultUserObject}
        chat={defaultChatObject}
        navDataItems={navDataItems}
        checkout={checkout}
        mobileExpandedSection={mobileExpandedSection}
        setMobileExpandedSection={setMobileExpandedSection}
        mobileNavOpen={mobileNavOpen}
        setMobileNavOpen={setMobileNavOpen}
        mounted={mounted}
        productCount={productCount}
        imageSearchFeatureFlag={imageSearchFeatureFlag}
      />
    </Provider>
  );
};

export default Header;
