import React from "react";
import { NavItemData } from "../DesktopNav/Item";

type Props = {
  clickedNavItem: NavItemData;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setClickedNavItem: React.Dispatch<
    React.SetStateAction<NavItemData | undefined>
  >;
};

const StepTwo = ({
  clickedNavItem,
  setCurrentStep,
  setClickedNavItem,
}: Props) => {
  return (
    <div className="m-0">
      <div
        className="border-gray-200
          border-solid
          border-t-0
          border-r-0
          border-l-0
          border-b
          py-2.5
          px-3"
      >
        <button
          className="border-none
            flex
            items-center
            font-bold
            text-xl
            p-0
            text-left
            text-gray-800
            w-full
            bg-white"
          type="button"
          onClick={() => {
            setCurrentStep(1);
            setClickedNavItem(undefined);
          }}
        >
          <svg
            className="pr-1"
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden
          >
            <path
              d="M6 12L1 6.5L6 1"
              stroke="#3b4238"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Back to Main Menu
        </button>
      </div>
      <div
        className="flex
          items-center
          justify-between
          font-bold
          font-semibold
          pt-3
          pb-4
          px-3
          text-gray-800
          text-base"
      >
        <p className="m-0">{clickedNavItem.displayName}</p>
        <a href={clickedNavItem.link} className="m-0 underline text-green-500">
          Shop All
        </a>
      </div>
      <ul className="list-none m-0">
        <li className="list-none">
          {clickedNavItem.featuredResources.map((featured) => (
            <a
              className="block
                font-medium
                pl-3
                text-gray-800
                text-base
                pb-4"
              href={featured.link}
              key={featured.name}
            >
              {featured.name}
            </a>
          ))}
        </li>
      </ul>
    </div>
  );
};

export default StepTwo;
