import React from "react";
import FastAnchor from "../../../FastAnchor";

interface StandardCenterLogoProps {
  classNames: string;
}

const StandardCenterLogo = ({ classNames }: StandardCenterLogoProps) => {
  return (
    <FastAnchor
      aria-label="Homepage, WebstaurantStore"
      href="/"
      data-testid="logo"
      className={`w-full xxl:w-auto ${classNames}`}
    >
      <svg
        aria-hidden="true"
        className="block max-w-[237px]"
        width="100%"
        height="22"
        viewBox="0 0 237 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.6217 21.3618L14.0383 7.32345L10.4822 21.3618H5.85422L0 0.919678H4.87322L8.4566 15.6938L12.3488 0.919678H15.7505L19.6155 15.6938L23.1671 0.919678H28.0721L22.2497 21.3618H17.6217Z"
          fill="white"
        />
        <path
          d="M26.3281 13.945C26.3281 9.65314 29.4846 6.19238 33.9899 6.19238C38.4952 6.19238 41.4065 9.50327 41.4065 14.3447V15.2031H30.4065C30.6518 17.0742 32.1551 18.6366 34.6666 18.6366C35.9247 18.6366 37.6687 18.087 38.6179 17.1651L40.3346 19.6766C38.8631 21.0255 36.5332 21.7295 34.2351 21.7295C29.7298 21.7295 26.3281 18.6956 26.3281 13.945ZM33.9899 9.28527C31.5692 9.28527 30.5246 11.0929 30.3429 12.5644H37.6369C37.546 11.1564 36.565 9.28527 33.9899 9.28527Z"
          fill="white"
        />
        <path
          d="M43.3049 21.3618V0.919678H47.1971V8.45887C48.3598 6.95557 50.0175 6.19257 51.7615 6.19257C55.5311 6.19257 58.3197 9.13558 58.3197 13.977C58.3197 18.8184 55.4993 21.7297 51.7615 21.7297C49.9857 21.7297 48.3598 20.9349 47.1971 19.4634V21.3618H43.3049ZM50.5352 18.2689C52.8015 18.2689 54.3048 16.5521 54.3048 13.977C54.3048 11.4019 52.8015 9.65787 50.5352 9.65787C49.2772 9.65787 47.8375 10.3936 47.1971 11.3746V16.6157C47.842 17.5649 49.2817 18.2689 50.5352 18.2689Z"
          fill="white"
        />
        <path
          d="M59.0554 19.4315L60.7404 16.702C61.8122 17.7148 64.0194 18.723 65.8588 18.723C67.5438 18.723 68.3431 18.0509 68.3431 17.1289C68.3431 14.6764 59.5459 16.6702 59.5459 10.8478C59.5459 8.36345 61.7214 6.18799 65.6136 6.18799C68.1251 6.18799 70.1189 7.04637 71.5904 8.20904L70.0281 10.875C69.1379 9.92579 67.4529 9.15825 65.6454 9.15825C64.2374 9.15825 63.3155 9.80317 63.3155 10.6298C63.3155 12.837 72.0809 10.9976 72.0809 16.9745C72.0809 19.6722 69.7828 21.7251 65.6772 21.7251C63.102 21.7251 60.6223 20.8667 59.0554 19.427"
          fill="white"
        />
        <path
          d="M75.4236 17.6831V9.96227H72.9711V6.56055H75.4236V2.51392H79.3476V6.56055H82.3496V9.96227H79.3476V16.6431C79.3476 17.5605 79.8381 18.269 80.6965 18.269C81.2778 18.269 81.8319 18.0555 82.0453 17.8375L82.8719 20.7806C82.2906 21.3028 81.246 21.7298 79.6246 21.7298C76.8951 21.7298 75.4281 20.3218 75.4281 17.6831"
          fill="white"
        />
        <path
          d="M93.5948 21.3616V19.8311C92.582 21.0256 90.838 21.7295 88.9078 21.7295C86.5461 21.7295 83.7575 20.1354 83.7575 16.8245C83.7575 13.5136 86.5461 12.0421 88.9078 12.0421C90.8698 12.0421 92.6183 12.687 93.5948 13.8497V11.8559C93.5948 10.3526 92.3095 9.37161 90.3475 9.37161C88.7534 9.37161 87.2819 9.95294 86.0284 11.1202L84.5569 8.51323C86.3645 6.9191 88.6943 6.18335 91.0242 6.18335C94.3941 6.18335 97.4916 7.53223 97.4916 11.7923V21.3526H93.5993L93.5948 21.3616ZM93.5948 17.8055V15.9661C92.9499 15.1078 91.7236 14.6808 90.4701 14.6808C88.9396 14.6808 87.6815 15.4756 87.6815 16.9199C87.6815 18.3641 88.9396 19.0953 90.4701 19.0953C91.7282 19.0953 92.9544 18.6639 93.5948 17.8101"
          fill="white"
        />
        <path
          d="M109.809 21.3616V19.4905C108.796 20.6259 107.02 21.7295 104.599 21.7295C101.352 21.7295 99.8169 19.9537 99.8169 17.0698V6.5603H103.709V15.5392C103.709 17.592 104.781 18.2688 106.439 18.2688C107.942 18.2688 109.136 17.4422 109.809 16.5838V6.5603H113.701V21.3616H109.809Z"
          fill="white"
        />
        <path
          d="M116.644 21.3616V6.56026H120.536V8.58131C121.576 7.26422 123.415 6.19238 125.255 6.19238V9.99377C124.978 9.93019 124.61 9.90294 124.183 9.90294C122.898 9.90294 121.181 10.6387 120.536 11.5879V21.3661H116.644V21.3616Z"
          fill="white"
        />
        <path
          d="M135.642 21.3616V19.8311C134.629 21.0256 132.885 21.7295 130.955 21.7295C128.593 21.7295 125.804 20.1354 125.804 16.8245C125.804 13.5136 128.593 12.0421 130.955 12.0421C132.917 12.0421 134.661 12.687 135.642 13.8497V11.8559C135.642 10.3526 134.356 9.37161 132.394 9.37161C130.8 9.37161 129.329 9.95294 128.075 11.1202L126.604 8.51323C128.411 6.9191 130.741 6.18335 133.071 6.18335C136.441 6.18335 139.538 7.53223 139.538 11.7923V21.3526H135.646L135.642 21.3616ZM135.642 17.8055V15.9661C134.997 15.1078 133.771 14.6808 132.517 14.6808C130.986 14.6808 129.728 15.4756 129.728 16.9199C129.728 18.3641 130.986 19.0953 132.517 19.0953C133.775 19.0953 135.001 18.6639 135.642 17.8101"
          fill="white"
        />
        <path
          d="M152.469 21.3616V12.4145C152.469 10.3616 151.397 9.65768 149.739 9.65768C148.209 9.65768 147.014 10.5161 146.369 11.4062V21.3661H142.477V6.56026H146.369V8.49047C147.318 7.35505 149.126 6.19238 151.547 6.19238C154.794 6.19238 156.356 8.03176 156.356 10.9112V21.3616H152.464H152.469Z"
          fill="white"
        />
        <path
          d="M160.68 17.6831V9.96227H158.227V6.56055H160.68V2.51392H164.604V6.56055H167.606V9.96227H164.604V16.6431C164.604 17.5605 165.094 18.269 165.953 18.269C166.534 18.269 167.088 18.0555 167.302 17.8375L168.128 20.7806C167.547 21.3028 166.502 21.7298 164.881 21.7298C162.151 21.7298 160.684 20.3218 160.684 17.6831"
          fill="white"
        />
        <path
          d="M169.445 18.4823L170.581 17.1652C171.898 18.6957 174.042 20.14 176.957 20.14C181.154 20.14 182.353 17.8101 182.353 16.0615C182.353 10.0574 170.095 13.1821 170.095 6.03805C170.095 2.69538 173.097 0.583496 176.807 0.583496C179.873 0.583496 182.139 1.65533 183.733 3.46292L182.571 4.74822C181.099 2.97242 179.015 2.17308 176.717 2.17308C173.987 2.17308 171.934 3.73542 171.934 5.94268C171.934 11.1838 184.192 8.30435 184.192 15.9344C184.192 18.5685 182.444 21.725 176.93 21.725C173.56 21.725 171.017 20.3761 169.454 18.4777"
          fill="white"
        />
        <path
          d="M187.744 18.5097V7.96847H185.291V6.56055H187.744V2.51392H189.37V6.56055H192.372V7.96847H189.37V18.2645C189.37 19.4589 189.86 20.2855 190.9 20.2855C191.604 20.2855 192.217 19.9494 192.558 19.5815L193.171 20.8078C192.59 21.3619 191.881 21.7252 190.659 21.7252C188.697 21.7252 187.748 20.5308 187.748 18.5052"
          fill="white"
        />
        <path
          d="M193.475 13.945C193.475 9.62589 196.264 6.19238 200.583 6.19238C204.902 6.19238 207.723 9.62589 207.723 13.945C207.723 18.2642 204.966 21.7295 200.583 21.7295C196.2 21.7295 193.475 18.2642 193.475 13.945ZM206.01 13.945C206.01 10.6659 204.08 7.6321 200.588 7.6321C197.095 7.6321 195.192 10.6659 195.192 13.945C195.192 17.2241 197.154 20.2898 200.588 20.2898C204.021 20.2898 206.01 17.2559 206.01 13.945Z"
          fill="white"
        />
        <path
          d="M209.685 21.3616V6.5603H211.279V9.10364C212.537 7.45047 214.222 6.25146 216.275 6.25146V8.00001C215.997 7.94097 215.784 7.94097 215.48 7.94097C213.976 7.94097 211.955 9.28985 211.279 10.5751V21.3616H209.685Z"
          fill="white"
        />
        <path
          d="M216.397 13.945C216.397 9.65314 219.431 6.19238 223.537 6.19238C228.042 6.19238 230.526 9.77577 230.526 14.0359V14.4673H218.146C218.3 17.6874 220.444 20.3533 223.936 20.3533C225.808 20.3533 227.52 19.6494 228.778 18.3005L229.604 19.3405C228.133 20.8711 226.266 21.7295 223.814 21.7295C219.495 21.7295 216.397 18.5094 216.397 13.945ZM223.509 7.57305C220.017 7.57305 218.268 10.6387 218.146 13.2138H228.9C228.869 10.7023 227.215 7.57305 223.505 7.57305"
          fill="white"
        />
        <path
          d="M236.667 8.16822C236.667 9.51255 235.613 10.5662 234.241 10.5662C232.87 10.5662 231.803 9.51255 231.803 8.16822C231.803 6.82388 232.883 5.802 234.241 5.802C235.599 5.802 236.667 6.85567 236.667 8.16822ZM232.407 8.16822C232.407 9.22189 233.188 10.0576 234.255 10.0576C235.322 10.0576 236.058 9.22189 236.058 8.18184C236.058 7.1418 235.291 6.27434 234.237 6.27434C233.183 6.27434 232.402 7.12817 232.402 8.16368L232.407 8.16822ZM233.864 9.4081H233.315V7.04188C233.533 6.99646 233.833 6.96921 234.223 6.96921C234.673 6.96921 234.873 7.04188 235.045 7.1418C235.177 7.24171 235.277 7.43247 235.277 7.65955C235.277 7.91842 235.077 8.1228 234.786 8.20909V8.23634C235.018 8.32263 235.145 8.49522 235.218 8.81314C235.291 9.17193 235.331 9.31726 235.39 9.40355H234.8C234.727 9.31726 234.687 9.09926 234.614 8.82676C234.568 8.56788 234.428 8.4498 234.123 8.4498H233.864V9.40355V9.4081ZM233.878 8.06376H234.137C234.441 8.06376 234.687 7.96384 234.687 7.71859C234.687 7.50059 234.528 7.35526 234.182 7.35526C234.037 7.35526 233.937 7.36888 233.878 7.38251V8.05922V8.06376Z"
          fill="white"
        />
      </svg>
    </FastAnchor>
  );
};

export default StandardCenterLogo;
