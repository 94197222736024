import React, { useEffect, useState } from "react";
import { cls } from "@wss/cls";
import PlusBadge from "../Logos/PlusBadge";
import Chevron from "../icons/Chevron";

interface PlusEssentialsAdWithCampaignDataProps {
  adCampaignData: {
    name: string;
    tabletImage: string;
    desktopImage: string;
    mobileBackgroundImage: string;
    link: string;
    couponCode: string;
    dollarAmountSaved: number;
    endDate: string;
    id: number;
    trackingTier: number;
    globalBannerIdOverride?: string;
    globalBannerClassnameOverride?: string;
    globalBannerTextOverride?: string;
  };
  modifyNameAndStyling: boolean;
}

const PlusEssentialsAdWithCampaignData = ({
  adCampaignData,
  modifyNameAndStyling,
}: PlusEssentialsAdWithCampaignDataProps) => {
  const {
    couponCode,
    dollarAmountSaved,
    link,
    name,
    trackingTier,
    globalBannerIdOverride,
    globalBannerClassnameOverride,
    globalBannerTextOverride,
  } = adCampaignData;

  let modifiedName = name;

  const [adElementId, setAdElementId] = useState("");
  const [adClassname, setAdClassname] = useState("");

  if (modifyNameAndStyling) {
    switch (name) {
      case "non-dairy milk":
        modifiedName = "non-dairy";
        break;
      case "flavoring syrups":
        modifiedName = "syrups";
        break;
      case "spreads":
        modifiedName = "spreads";
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (globalBannerIdOverride) {
      setAdElementId(globalBannerIdOverride);
    } else {
      setAdElementId(
        `global-plus-essentials-${modifiedName.replace(
          " ",
          "-"
        )}-tier${trackingTier}`
      );
    }

    if (globalBannerClassnameOverride) {
      setAdClassname(globalBannerClassnameOverride);
    } else {
      setAdClassname(
        `global-plus-essentials-${modifiedName.replace(" ", "-")}`
      );
    }
  }, [
    globalBannerIdOverride,
    globalBannerClassnameOverride,
    modifiedName,
    trackingTier,
  ]);

  return (
    <a
      data-testid="global-plus-essentials-ad"
      id={adElementId}
      href={link}
      className={adClassname}
    >
      <div className="text-white text-xs hover:underline flex justify-center items-center h-[17px] whitespace-pre">
        <PlusBadge classNames="pr-2 xxs:block hidden" />
        {globalBannerTextOverride ? (
          <span>{globalBannerTextOverride}</span>
        ) : (
          <>
            Get ${dollarAmountSaved} in FREE{" "}
            <span className="capitalize">{name}</span>
          </>
        )}

        <span> | </span>
        <span
          className={cls("font-bold", modifyNameAndStyling && "text-[#29C5FF]")}
        >
          Use Code: {couponCode}
        </span>
        <Chevron
          strokeColor={modifyNameAndStyling ? "#29C5FF" : "#FFFFFF"}
          classNames="pl-2"
        />
      </div>
    </a>
  );
};

export default PlusEssentialsAdWithCampaignData;
