import React from "react";
import { connect } from "react-redux";

import CustomMessage from "./CustomMessage";
import HelpCenter from "./HelpCenter";
import PhoneApp from "./PhoneApp";
import { ChatState, RootState } from "~/common/Store/models/RootReadonly";

interface AlertBannerLeftContainerProps {
  chat: ChatState;
  checkout: boolean;
  eventDayBannerVisible: boolean;
  isCustomerServiceMessageAvailable: boolean;
  isEventDayDataLoading: boolean;
  isPlusEssentials?: boolean;
  mounted: boolean;
  pageCreatorTextColor?: string;
}

const AlertBannerLeftContainer = ({
  chat,
  checkout,
  eventDayBannerVisible,
  isCustomerServiceMessageAvailable,
  isEventDayDataLoading,
  isPlusEssentials,
  pageCreatorTextColor,
}: AlertBannerLeftContainerProps) => {
  const showAppAd =
    !isCustomerServiceMessageAvailable &&
    !isEventDayDataLoading &&
    !checkout &&
    !pageCreatorTextColor &&
    !isPlusEssentials;

  return (
    <div
      data-testid="alert-banner-left-container"
      className={`"items-center space-x-6 hidden lt:flex flex-initial" ${
        checkout ? "lt:h-5" : ""
      }`}
    >
      {!isEventDayDataLoading && (
        <HelpCenter
          isPlusEssentials={isPlusEssentials}
          pageCreatorTextColor={pageCreatorTextColor}
        />
      )}
      {isCustomerServiceMessageAvailable && !isEventDayDataLoading && (
        <CustomMessage
          {...{
            ...chat.headerBanner,
            eventDayBannerVisible,
            isPlusEssentials,
            pageCreatorTextColor,
          }}
        />
      )}
      {showAppAd && <PhoneApp isPlusEssentials={isPlusEssentials} />}
    </div>
  );
};

export const mapStateToProps = (
  state: RootState,
  ownProps: AlertBannerLeftContainerProps
) => {
  if (!ownProps.mounted) {
    return {};
  }

  return state;
};

export default connect(mapStateToProps)(AlertBannerLeftContainer);
