/*
This file acts as an alias for the class name package currently installed.
In the event Storefront needs to use a different class name library,
just replace the import from this file with the new one and the entire
codebase will be updated.
*/

import { twMerge } from "tailwind-merge";
// eslint-disable-next-line filenames/match-exported, import/extensions
import clsx, { ClassValue } from "../../../node_modules/clsx";

// eslint-disable-next-line import/prefer-default-export
export const cls = (...classes: ClassValue[]) => twMerge(clsx(...classes));
