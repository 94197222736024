/* eslint-disable camelcase */
import SHA256 from "crypto-js/sha256";
import { Throwable } from "@wss/error-tracking/throwable";
import Environment from "~/common/Environment";
import Store from "~/common/Store";
import MarketingEventHelpers from "./MarketingHelpers/MarketingEventHelpers";
import { getDynamicData } from "~/common/Store/actions/updateUserAndChatFromOrigin";

export const getFacebookPixelId = () => {
  return Environment.primary === "dev" ? "230878098552719" : "480797725416035";
};

// Note this has to be in a separate file from Pinterest class to avoid circular dependency
export const getUserForPinterestEvent = () => {
  const { user } = Store.getState();
  return {
    ...user.facebookUserData,
    external_id: user.index ?? "0",
    ip: user.analyticsData.ip,
    user_agent: navigator.userAgent,
  };
};

export const getFacebookPixelUserData = () => {
  const { user } = Store.getState();
  return { ...user.facebookUserData, external_id: user.index ?? "0" };
};

export const sendFacebookPixelServerEvent = async (
  eventName: string,
  eventId: string,
  userData: Record<string, string>,
  eventParams = {}
): Promise<Throwable> => {
  await getDynamicData;
  const userDataThatShouldNotBeHashed = [
    "facebookClickId",
    "facebookBrowserId",
  ];

  const combinedUserData = Object.keys(userData).reduce(
    (p, c) => ({
      ...p,
      [c]: userDataThatShouldNotBeHashed.includes(c)
        ? userData[c]
        : SHA256(userData[c] ?? "").toString(),
    }),
    {}
  );

  const fullUserData = {
    ...combinedUserData,
    client_ip_address: Store.getState().user.analyticsData.ip ?? "",
    client_user_agent: navigator.userAgent,
  };

  type FacebookServerUserData = {
    ct?: string;
    country?: string;
    em?: string;
    fn?: string;
    ln?: string;
    ph?: string;
    st?: string;
    zp?: string;
    external_id?: string;
    client_ip_address: string;
    client_user_agent: string;
    facebookClickId?: string;
    facebookBrowserId?: string;
  };

  type FacebookServerEvent = {
    /* eslint-disable camelcase */
    event_name: string;
    event_time: number;
    event_id: string;
    event_source_url: string;
    action_source: string;
    user_data: FacebookServerUserData;
    custom_data?: unknown;
  };

  const data: FacebookServerEvent = {
    event_name: eventName,
    event_time: Math.floor(Date.now() / 1000),
    event_id: eventId,
    event_source_url: MarketingEventHelpers.removeEmailFromUrl(
      new URL(window.location.href)
    ),
    action_source: "website",
    user_data: fullUserData,
    custom_data: eventParams,
  };

  const url = new URL(
    "/facebookConversion",
    `https://${Environment.stashDomainWithEnvironment}`
  );

  try {
    const result = await fetch(url.href, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!result.ok) {
      return {
        isError: true,
        error: new Error(
          `Request to "${url.href}" failed with response ${result.statusText}`
        ),
      };
    }
    return { isError: false };
  } catch (error) {
    return {
      isError: true,
      error: new Error(
        `Something went wrong with when requesting ${url.href}`,
        { cause: error }
      ),
    };
  }
};
