import React from "react";

interface DropDownProps {
  children: React.ReactNode;
  active: boolean;
  menuRef: React.RefObject<HTMLDivElement>;
  isCheckout?: boolean;
}

const MenuDropdown = ({
  children,
  active,
  menuRef,
  isCheckout = false,
}: DropDownProps) => {
  return (
    <div
      ref={menuRef}
      className={`bg-white rounded list-none ml-0 mt-1 absolute right-0 z-[60] shadow-xl min-w-[340px] bg-clip-padding ${
        active ? "block" : "hidden"
      }  min-w-[340px] ${!isCheckout && "lt:left-[-73px] xxl:left-0"}
      }`}
    >
      {children}
    </div>
  );
};

export default MenuDropdown;
