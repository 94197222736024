import { cls } from "cls";
import React from "react";

type Props = {
  isExpanded: boolean;
};

const ExpandChevron = ({ isExpanded }: Props) => (
  <svg
    className={cls(
      "transform",
      "transition",
      "ease-linear",
      isExpanded ? "-rotate-90" : "rotate-90"
    )}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 5 9"
    width="8"
    height="13"
    aria-hidden
  >
    <path
      d="M1 8L4 4.5L1 1"
      stroke="#3b4238"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ExpandChevron;
