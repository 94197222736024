import { sendGtmEvent } from "../definitions";

export const PageTypes = {
  QuickCheckout: 2,
  BillingPage: 3,
  ReviewPayment: 4,
  Checkout: 4,
};

// eslint-disable-next-line import/prefer-default-export
export const sendCheckoutEventPageView = (
  pageType: keyof typeof PageTypes,
  funnelType: string
) => {
  sendGtmEvent({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: PageTypes[pageType],
          option: funnelType,
        },
      },
    },
  });
};
