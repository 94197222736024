import React from "react";
import { Icon } from "@clarkinc/zest-design-system";
import Chevron from "./icons/Chevron";

import spritePath from "~/common/Icon/critical-symbol.svg";

interface HelpCenterProps {
  isPlusEssentials?: boolean;
  pageCreatorTextColor?: string;
}

const HelpCenter = ({
  isPlusEssentials,
  pageCreatorTextColor = "",
}: HelpCenterProps) => {
  const changeStylesDependingOnEvent = (
    plusEssentialColor: string,
    alertBannerDefaultColor: string
  ) => {
    if (
      pageCreatorTextColor &&
      pageCreatorTextColor.length > 3 &&
      !isPlusEssentials
    ) {
      return pageCreatorTextColor;
    }
    if (isPlusEssentials) {
      return plusEssentialColor;
    }
    return alertBannerDefaultColor;
  };

  return (
    <div className="flex items-center">
      <a
        href="/ask.html"
        className={`inline-flex items-center font-semibold text-xs hover:underline tracking-[.02em] ${
          isPlusEssentials ? "text-white" : "text-gray-800"
        }`}
        data-testid="help-center-banner"
        style={
          pageCreatorTextColor && !isPlusEssentials
            ? { color: pageCreatorTextColor }
            : {}
        }
      >
        <Icon
          name="question-mark-small"
          spriteSheetHref={spritePath}
          size="2xsmall"
          className="h-4 w-4 mr-0.5"
        />
        Help Center
        <Chevron
          classNames="pl-1 mt-px"
          strokeColor={changeStylesDependingOnEvent("#FFFFFF", "#3b4238")}
        />
      </a>
    </div>
  );
};

export default HelpCenter;
