import { Toaster } from "@clarkinc/zest-design-system/ui";
import whenDomReady from "when-dom-ready";
import { polyfills } from "./polyfills";
import Mount from "./common/Mount";
import Header from "./common/Header";
import { loadUserAnalytics } from "./gtm";
import renderFromPreloadedJSON from "./common/renderFromPreloadedJSON";
import tracker from "./tracker";
import ajaxToken from "./ajaxToken";
import { logException } from "./__alias__/error-tracking";
import { env } from "./common/Environment";
/** Used in conjunction with SWC and BrowserList
 * @see {@link https://swc.rs/docs/configuration/supported-browsers#mode SWC Docs}
 */
import "core-js";
/** Manually added due to the following issue.
 * @see {@link https://github.com/swc-project/swc/issues/7083 Github Issue}
 */
import "core-js/actual/array/at";

/** This maybe moved to a new entry that is only for the webpack running in watch mode */
if (module.hot) {
  module.hot.accept();
}

import(
  /* webpackChunkName: "start-error-logging" */ "./common/startErrorLogging"
);

if (
  !window.location.pathname.startsWith("/blog") &&
  !window.location.pathname.startsWith("/careers")
) {
  import(
    /* webpackChunkName: "common-critical-styles" */ "~/less/_critical/include.less"
  );
  import(/* webpackChunkName: "tailwind" */ "~/css/tailwind.css");
}

if (window.location.href.includes("webcache.googleusercontent.com")) {
  import("./googleWebCache");
}

const loadGdprBanner = async () => {
  const gdprBannerMount = document.getElementById("gdprBannerMount");
  const { storageFactory } = await import("storage-factory");

  const storage = storageFactory(() => window.localStorage);

  if (gdprBannerMount) {
    if (
      !storage.getItem("data-policy-seen") &&
      !storage.getItem("outsideOfEuropeanUnion")
    ) {
      const { default: GdprBanner } = await import("./common/GdprBanner");
      Mount(GdprBanner, gdprBannerMount.dataset, gdprBannerMount);
    }
  }
};

const loadProductListingFilter = async () => {
  const filterBar = document.querySelector(".ag-filter");

  if (filterBar) {
    const { default: ProductListingFilter } = await import(
      "./common/ProductListingFilter"
    );
    new ProductListingFilter();
  }
};

const loadDynamicModules = () => {
  window.dynamicModules = window.dynamicModules || [];

  const modules = {
    customCategory: () => {
      import("./customCategory");
    },
    cart: () => {
      import("./cart/index");
    },
    checkout: () => {
      import("./checkout/index");
    },
    vendor: () => {
      import("./vendor");
    },
    orderTracking: () => {
      import("./my-account/orderTracking");
    },
    videoPlayer: () => {
      import("./common/videoPlayer");
    },
    productListing: () => {
      import(/* webpackChunkName: "productListing" */ "./productListing");
    },
    home: () => {
      import(/* webpackChunkName: "home" */ "./home");
    },
    compatibleWith: () => {
      import("./compatible-with");
    },
    compareProducts: () => {
      import("./compareProducts");
    },
    extendedWarranties: () => {
      import("./extendedWarranties");
    },
    customDoors: () => {
      import("./custom-doors/index");
    },
    holiday: () => {
      import("./holiday");
    },
    myAccount: () => {
      import("./my-account/index");
    },
    myAccountPayment: () => {
      import("./my-account/payment/index");
    },
    myAccountRequestAdditionalFunds: () => {
      import("./my-account/orders/payment/index");
    },
    initWishList: () => {
      import("./WishListManager/initWishList");
    },
    viewinfoPayment: () => {
      import("./checkout/viewInfoCard/index");
    },
    viewinfoNewPayment: () => {
      import("./checkout/viewInfoNewPayment/index");
    },
    signUpAndSave: () => {
      import("./sign-up-and-save");
    },
    initSpecializedPage: () => {
      import("./productListing/initSpecializedPage");
    },
    initCategoryPage: () => {
      import("./CategoryPage/initCategoryPage");
    },
    initLeafCategoryPage: () => {
      import("./productListing/LeafCategoryPage/initCategoryPage");
    },
    vendorApplication: () => {
      import("./vendorApplication");
    },
    brochureVendorApplication: () => {
      import("./Brochure/VendorApplication");
    },
    specialCategory: () => {
      import("./jquery.special.category");
    },
    brandListing: () => {
      import("./Brands");
    },
    storeFrontBanner: () => {
      import("./storeFrontBanner/initStoreFrontBannerWithExternalData");
    },
    initStoreFrontBanner: () => {
      import("./storeFrontBanner/index.legacy");
    },
    initSupSubTable: () => {
      import("./my-account/SupSubTable/initSupSubTable");
    },
    initEditSubordinate: () => {
      import("./my-account/EditSubordinate/initEditSubordinate");
    },
    initOrderGuides: () => {
      import("./my-account/OrderGuides/initOrderGuides");
    },
    initSubordinateOrderGuides: () => {
      import("./my-account/SubordinateOrderGuides/initSubordinateOrderGuides");
    },
    guestCheckout: () => {
      /* webpackChunkName: "guestCheckout" */ import("./checkout/guest");
    },
    tableTop: () => {
      import("./tableTop/index");
    },
    tabletopViewer: () => {
      import("./3DtabletopViewer");
    },
    initPriceChopperPage: () => {
      import("./productListing/PriceChopperPage/initPriceChopperPage");
    },
    initSearchPage: () => {
      import("./productListing/SearchPage/initSearchPage");
    },
    initOutletPage: () => {
      import("./productListing/OutletPage/initOutletPage");
    },
    initMixNMatchPage: () => {
      import("./productListing/MixNMatchPage/initMixNMatchPage");
    },
    initPurchasingGroupPage: () => {
      import("./productListing/PurchasingGroupPage/initPurchasingGroupPage");
    },
    initCompareProductsPage: () => {
      import("./productListing/CompareProductsPage/initCompareProductsPage");
    },
    initCompatibleWithPage: () => {
      import("./productListing/CompatibleWithPage/initCompatibleWithPage");
    },
    initYouMayAlsoNeedPage: () => {
      import("./productListing/YouMayAlsoNeedPage/initYouMayAlsoNeedPage");
    },
    initNewItemsPage: () => {
      import("./productListing/NewItemsPage/initNewItemsPage");
    },
    initPlusPage: () => {
      import("./PlusPage/initPlusPage");
    },
    initProductDetail: () => {
      import("./ProductDetails/initProductDetail");
    },
    initProductReviews: () => {
      import("./ProductReviews/initProductReviews");
    },
    initVideoModalContainer: () => {
      import("./common/VideoModalContainer/initVideoModalContainer");
    },
    initSurveyPage: () => {
      import("./my-account/Survey/initSurveyPage");
    },
    initSupportPage: () => {
      import("./StaticContentPages/SupportPage");
    },
    rewards: () => {
      import("./Rewards/index");
    },
    autoreorderPagination: () => {
      import("./my-account/autoreorder/autoreorderPagination");
    },
    initHelpBlock: () => {
      import("./common/Layout/HelpBlock/initHelpBlock");
    },
    specialOrdersRequest: () => {
      import("./Brochure/SpecialOrdersRequest");
    },
    signUpAndSavePage: () => {
      import("./Brochure/SignUpAndSave");
    },
    scholarshipDashboard: () => {
      import("./Brochure/Scholarship/ScholarshipDashboard");
    },
    foodserviceHospitalityScholarship: () => {
      import("./Brochure/Scholarship/FoodserviceHospitalityScholarship");
    },
    diversityScholarship: () => {
      import("./Brochure/Scholarship/DiversityScholarship");
    },
    samplesRequest: () => {
      import("./Brochure/SamplesRequest");
    },
    blogCouponCodesSignup: () => {
      import("./Brochure/Blog/BlogCouponCodesSignup");
    },
    duplicateOrderModal: () => {
      import("./checkout/duplicateOrderModal/index.js");
    },
    blogHeader: () => {
      import("./Brochure/Blog/BlogHeader");
    },
    blogSocialMediaShareIcons: () => {
      import("./Brochure/Blog/BlogSocialMediaShareIcons");
    },
    initCheckoutPayment: () => {
      import("./FullCheckout/CheckoutPayment/init.js");
    },
    initOrderConfirmation: () => {
      import("./FullCheckout/CheckoutComplete/OrderConfirmation/init.js");
    },
    initSubmitToSupervisor: () => {
      import("./FullCheckout/CheckoutComplete/SubmitToSupervisor/init.js");
    },
    initReviewOrder: () => {
      import("./FullCheckout/CheckoutComplete/ReviewOrder/init.js");
    },
    initOrderConfirmationError: () => {
      import("./FullCheckout/CheckoutComplete/Error/init.js");
    },
    quoteRequest: () => {
      import("./Brochure/QuoteRequest");
    },
    initAutoReorderManagement: () => {
      import("./my-account/autoreorder/init");
    },
    beforeYouContact: () => {
      import("./Brochure/BeforeYouContact");
    },
    searchFrequentlyAskedQuestions: () => {
      import("./Brochure/SearchFrequentlyAskedQuestions");
    },
    secureUpload: () => {
      import("./Brochure/SecureUpload");
    },
    restaurantLayoutTool: () => {
      import("./Brochure/RestaurantLayoutTool/index.js");
    },
    supportPage: () => {
      import("./Brochure/Support");
    },
    foodServiceArticlesSearchResults: () => {
      import(
        "./Brochure/FoodServiceResources/FoodServiceResourcesSearchResults"
      );
    },
    foodServiceResourcesSearch: () => {
      import(
        "./Brochure/FoodServiceResources/FoodServiceResourcesSidebar/FoodServiceResourcesSearch"
      );
    },
    foodServiceResourcesSubscribe: () => {
      import(
        "./Brochure/FoodServiceResources/FoodServiceResourcesSidebar/FoodServiceResourcesSubscribe"
      );
    },
    foodServiceResourcesCategories: () => {
      import(
        "./Brochure/FoodServiceResources/FoodServiceResourcesSidebar/FoodServiceResourcesCategories"
      );
    },
    foodServiceResourcesHeader: () => {
      import("./Brochure/FoodServiceResources/FoodServiceResourcesHeader");
    },
    foodServiceResourcesIndex: () => {
      import("./Brochure/FoodServiceResources/FoodServiceResourcesHomepage");
    },
    foodServiceResourcesArticle: () => {
      import("./Brochure/FoodServiceResources/FoodServiceResourcesArticle");
    },
    foodServiceResourcesBuyingGuide: () => {
      import("./Brochure/FoodServiceResources/FoodServiceResourcesBuyingGuide");
    },
    foodServiceResourcesVideo: () => {
      import("./Brochure/FoodServiceResources/FoodServiceResourcesVideo");
    },
    foodServiceResourcesVideosLanding: () => {
      import(
        "./Brochure/FoodServiceResources/FoodServiceResourcesVideosLanding"
      );
    },
    foodServiceResourcesVideosSearch: () => {
      import("./Brochure/FoodServiceResources/FoodServiceResourcesVideoSearch");
    },
    foodServiceResourcesReviewPage: () => {
      import("./Brochure/FoodServiceResources/FoodServiceResourcesReviewPage");
    },
    blogHomepage: () => {
      import("./Brochure/Blog/BlogHomepage");
    },
    blogPostContent: () => {
      import("./Brochure/Blog/BlogPostContent");
    },
    careersIndex: () => {
      import("./Brochure/Careers/Index");
    },
    careersDepartment: () => {
      import("./Brochure/Careers/Department");
    },
    careersJobListing: () => {
      import("./Brochure/Careers/JobListing");
    },
    careersAlumni: () => {
      import("./Brochure/Careers/Alumni");
    },
    emailPreferences: () => {
      import("./Brochure/EmailPreferences");
    },
    recipeResizer: () => {
      import("./Brochure/RecipeResizer");
    },
    toastLandingPage: () => {
      import("./Brochure/ToastLanding");
    },
    initViewCart: () => {
      import("./ViewCart/initViewCart");
    },
    initShippingBillingInfo: () => {
      import("./ShippingBillingInfo/initShippingBillingInfo");
    },
    initVersaHub: () => {
      import("./versahub/initVersaHub");
    },
    foodServiceResourcesTypeLanding: () => {
      import("./Brochure/FoodServiceResources/FoodServiceResourcesTypeLanding");
    },
    foodServiceResourcesCategoryLanding: () => {
      import(
        "./Brochure/FoodServiceResources/FoodServiceResourcesCategoryLanding"
      );
    },
    partsAndAccessories: () => {
      import("./Brochure/PartsAndAccessories");
    },
    initHomePage: () => {
      import("./HomePage/initHomePage");
    },
    initCreditKeyPage: () => {
      import("./CreditKeyPage/initCreditKeyPage");
    },
    initRapidReorder: () => {
      import("./RapidReorderManager/initRapidReorder");
    },
    initCustomerReview: () => {
      import("./my-account/CustomerReview/initCustomerReview");
    },
    initPageCreatorPage: () => {
      import("./PageCreatorPage/initPageCreatorPage");
    },
    initPageCreatorItemListingPage: () => {
      import(
        "./PageCreatorPage/ItemListingPage/initPageCreatorItemListingPage"
      );
    },
    initPageCreatorInactivePage: () => {
      import("./PageCreatorPage/InactivePage/initPageCreatorInactivePage");
    },
    initPageCreatorLeadGenerationPage: () => {
      import(
        "./PageCreatorPage/LeadGenerationPage/initPageCreatorLeadGenerationPage"
      );
    },
    initPagination: () => {
      import("./common/Pagination/initPagination");
    },
    initLeftNavbar: () => {
      import("./my-account/LeftNavbar/initLeftNavbar");
    },
    initInternalNotesContent: () => {
      import("./ProductDetails/InternalNotes/initInternalNotesContent");
    },
    initAltoShaamDemoPage: () => {
      import("./Brochure/AltoShaamDemoPage/initAltoShaamDemoPage");
    },
    initCurtronDoorPage: () => {
      import("./CurtronDoorPage/initCurtronDoorPage");
    },
    initMobilePaymentSelection: () => {
      import("./checkout/viewInfoCard/initMobilePaymentSelection");
    },
    initMobileApplePayButton: () => {
      import("./checkout/mobile/initMobileApplePayButton");
    },
    initFirstOrderPage: () => {
      import("./FirstOrderPage/initFirstOrderPage");
    },
    initCustomizableToolPage: () => {
      import("./CustomizableToolPage/initCustomizableToolPage");
    },
    initWebPlusSubscriptions: () => {
      import("./my-account/WebPlusSubscriptions/initWebPlusSubscriptions");
    },
    initPlusHeader: () => {
      import("./my-account/WebPlusSubscriptions/PlusHeader/initPlusHeader");
    },
    initWebPlusAd: () => {
      import("./my-account/WebPlusSubscriptions/WebPlusAd/initWebPlusAd");
    },
    initMyAccountDashboardFreeviewAd: () => {
      import(
        "./my-account/Dashboard/FreeviewAd/initMyAccountDashboardFreeviewAd.js"
      );
    },
    initImageSearchPage: () => {
      import("./productListing/ImageSearchPage/initImageSearchPage");
    },
    initSurveyAlert: () => {
      import("./my-account/CurrentInstallations/SurveyAlert/initSurveyAlert");
    },
    initMyAccountDashboardPlusEssentialsAdWrapper: () => {
      import(
        "./my-account/Dashboard/PlusEssentialsAd/initMyAccountDashboardPlusEssentialsAdWrapper"
      );
    },
    initMyAccountDashboardCarousel: () => {
      import("./my-account/Dashboard/Carousel/initMyAccountDashboardCarousel");
    },
    initBlogFooter: () => {
      import("./Brochure/Blog/BlogFooter");
    },
    initExpiringCreditCards: () => {
      import("./my-account/Common/ExpiringCreditCards/initExpiringCreditCards");
    },
    initOrderHistory: () => {
      import("./my-account/OrderHistory/initOrderHistory");
    },
    initRecentOrders: () => {
      import("./my-account/Dashboard/RecentOrders/initRecentOrders");
    },
    initOrderDetail: () => {
      import("./my-account/OrderDetail/initOrderDetail");
    },
    initTrackOrder: () => {
      import("./my-account/TrackOrder/initTrackOrder");
    },
    initReturns: () => {
      import("./my-account/Returns/initReturns");
    },
  };

  for (const name of window.dynamicModules) {
    if (!(name in modules)) {
      logException(
        new Error(
          `Key ${name} is in the modules object. Make sure it is supposed to be in the dynamicModules array`
        )
      );
      // eslint-disable-next-line no-continue
      continue;
    }
    modules[name]();
  }

  document.querySelectorAll("[data-module]").forEach(async (element) => {
    const moduleName = element.getAttribute("data-module");
    if (moduleName) {
      modules[moduleName]();
    }
  });

  if (!env.isProd) modules.initStoreFrontBanner();
  import("./Conductrics");
};

const hydrateHeader = () => {
  renderFromPreloadedJSON(Header, "GlobalHeader", true);
};

const hydrateFooter = async () => {
  if (document.querySelector('[data-hypernova-key="Footer"]')) {
    const { default: Footer } = await import("./common/Footer");
    renderFromPreloadedJSON(Footer, "Footer");
  }
};

const main = async () => {
  await polyfills;
  Mount(Toaster, {}, document.createElement("div"));
  if (document.querySelector('[data-hypernova-key="GlobalHeader"]')) {
    setTimeout(hydrateHeader);
  } else {
    document.addEventListener("hydrateHeader", () => setTimeout(hydrateHeader));
  }

  whenDomReady(() => {
    loadDynamicModules();

    setTimeout(loadGdprBanner);

    setTimeout(loadProductListingFilter);

    setTimeout(() =>
      import(/* webpackChunkName: "recaptcha" */ "~/less/recaptcha.less")
    );

    if (!window.location.pathname.includes("cloudflareerror")) {
      setTimeout(loadUserAnalytics);
    }

    setTimeout(tracker);

    setTimeout(ajaxToken);

    setTimeout(hydrateFooter);
    import("./fixForBootstrapNative");
  });
};

main();
