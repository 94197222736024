import React, { useEffect, useCallback } from "react";

function useOnClickOutside(
  menuContainerRef: React.RefObject<HTMLDivElement>,
  buttonsRef: React.RefObject<HTMLButtonElement>,
  handler: (event: MouseEvent) => void
) {
  const listener = useCallback(
    (event: MouseEvent) => {
      if (
        !menuContainerRef.current ||
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        menuContainerRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }
      if (
        !buttonsRef.current ||
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        buttonsRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }
      handler(event);
    },
    [buttonsRef, handler, menuContainerRef]
  );
  useEffect(() => {
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [handler, listener, menuContainerRef]);
}

export default useOnClickOutside;
