import React from "react";
import { Icon } from "@clarkinc/zest-design-system";

interface ChevronProps {
  classNames?: ClassTypes;
  strokeColor?: string;
}

const Chevron = ({ classNames, strokeColor = "#3b4238" }: ChevronProps) => {
  return (
    <Icon
      className={`${classNames}`}
      height="12px"
      name="ri-arrow-right-s-line"
      size="2xsmall"
      style={{ fill: strokeColor }}
      width="12px"
    />
  );
};

export default Chevron;
