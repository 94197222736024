import React, { useState, useEffect, useRef } from "react";
import { PageCreatorBanner } from "./PageCreatorBanner";
import { UserState } from "~/common/Store/models/RootReadonly";

interface SiteWideEventDayBannerProps {
  pageCreatorBanner: PageCreatorBanner | null;
  classNames?: string;
  user?: UserState;
}

const SiteWideEventDayBanner = ({
  pageCreatorBanner,
  classNames = "",
  user,
}: SiteWideEventDayBannerProps) => {
  const { bannerText, relativeUrl, textColor } = pageCreatorBanner ?? {
    bannerText: "",
    relativeUrl: "",
    textColor: "",
  };

  const [hideLogo, setHideLogo] = useState(false);
  const containerRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setHideLogo(containerRef.current.scrollHeight > 16);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [containerRef]);

  const hasFirstName = bannerText.includes("[First Name]");

  const bannerTextWithName = user?.firstName
    ? bannerText.replace("[First Name]", user.firstName)
    : bannerText.replace("[First Name]", "");
  return (
    <p
      className={`font-semibold text-xs m-0 text-center flex-grow-0 ${classNames}`}
      data-testid="PageCreatorSiteWideBanner"
    >
      <a
        href={relativeUrl}
        style={{ color: textColor }}
        className="inline-flex flex-wrap gap-1 items-center align-middle justify-center"
        ref={containerRef}
      >
        {pageCreatorBanner?.bannerLogoUrl && !hideLogo && (
          <img
            src={pageCreatorBanner.bannerLogoUrl}
            alt={pageCreatorBanner.bannerLogoAlternateText}
            data-testid="banner-logo"
          />
        )}
        {hasFirstName ? bannerTextWithName : bannerText}
        <span className="hover:underline">Shop Now</span>
      </a>
    </p>
  );
};

export default SiteWideEventDayBanner;
